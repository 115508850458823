import { Image } from "react-bootstrap";

export const Coupon = () => {
    return (
        <div className="text-white">
            <Image src="https://img-9gag-fun.9cache.com/photo/azbogpj_460s.jpg" alt="Some easter egg pic"/>
            <h4 className="mt-5">Whoops, I didn't expect people to find my easter egg page so fast, this is still a work
                in progress.</h4>
        </div>
    );
}
